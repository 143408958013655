var LogInModal_1;
import { __decorate } from "tslib";
import { AuthServiceType, EventbusType, LocalStorage } from '@movecloser/front-core';
import { Component } from 'vue-property-decorator';
import { AbstractModal } from '@component/Modal';
import { Alert, AlertTheme } from '@component/Alert';
import { EModal } from '@contract/modal';
import { Inject } from '@plugin/inversify';
import { Screen } from '@component/Screen';
import { LoginIntention } from '../../../intentions';
import { AuthRepositoryType } from '../../../contracts/repositories';
import { GrantType } from '../../../contracts/models';
import { Socials } from '../../components/Socials';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Michał Rossian <michal.rosssian@movecloser.pl>
 */
let LogInModal = LogInModal_1 = class LogInModal extends AbstractModal {
    constructor() {
        super(...arguments);
        this.alertTheme = AlertTheme.Warning;
        this.errors = '';
        this.hasErrors = false;
        this.isActing = false;
        /**
         * Object that collects User input.
         */
        this.formData = {
            email: '',
            password: '',
            remember: false
        };
    }
    onAlertClose() {
        this.hasErrors = false;
    }
    onCreateAccount() {
        this.swapModal(EModal.Welcome);
    }
    /**
     * Handles the `@click` event on the "forgot password" button.
     */
    onForgotPasswordBtnClick() {
        this.swapModal(EModal.ForgotPassword);
    }
    /**
     * Handles the `@submit` event on the `<form>` element.
     */
    onSubmit() {
        this.logInUser();
    }
    /**
     * Logs the User in.
     */
    async logInUser() {
        this.isActing = true;
        const { email, password, remember } = this.formData;
        this.authRepository.logIn(new LoginIntention({
            email,
            password,
            remember,
            grantType: GrantType.Credentials
        }))
            .then((model) => {
            this.authService.setUser(model.getUser());
            this.authService.setToken(model.toAuthToken());
            this.$store.dispatch('profile/setMe', model.getUser());
            if (typeof window !== 'undefined' && typeof document !== 'undefined') {
                LocalStorage.set('d24-user-id', model.getUser().uniqueId);
                LocalStorage.set('d24-user-com-id', model.getUser().userComId);
                LocalStorage.set('d24-client-id', model.getUser().id);
                LocalStorage.set('d24-client-sub', model.getUser().subscriptionType);
            }
            this.eventBus.emit('auth:logged-in', model.getUser());
            if (this.hasPayload && typeof this.payload.onSuccess === 'function') {
                this.payload.onSuccess();
            }
            else {
                this.close();
            }
        })
            .catch((e) => {
            this.errors = e.payload && e.message ? e.message : '';
            this.hasErrors = true;
        })
            .finally(() => {
            this.isActing = false;
        });
    }
    async handleOnSocialSelect(social) {
        this.isActing = true;
        this.authRepository.logInWithSocial(social)
            .then((redirectUrl) => {
            localStorage.setItem('oauthProcess', 'login');
            const originUrl = this.$route.name === 'profile.oauth' ? `${LogInModal_1.getDomain()}/` : window.location.href;
            localStorage.setItem('originUrl', originUrl);
            localStorage.setItem('oauthProvider', social);
            window.location.href = redirectUrl;
            this.close();
        })
            .catch((e) => {
            this.hasErrors = true;
        })
            .finally(() => {
            this.isActing = false;
        });
    }
    static getDomain() {
        const currentURL = window.location.href;
        const urlObject = new URL(currentURL);
        const protocol = urlObject.protocol;
        const domain = urlObject.hostname;
        const port = urlObject.port;
        // Combine protocol and domain to form the full domain with https
        const fullDomain = port ? `${protocol}//${domain}:${port}` : `${protocol}//${domain}`;
        return fullDomain;
    }
};
__decorate([
    Inject(AuthRepositoryType)
], LogInModal.prototype, "authRepository", void 0);
__decorate([
    Inject(EventbusType)
], LogInModal.prototype, "eventBus", void 0);
__decorate([
    Inject(AuthServiceType)
], LogInModal.prototype, "authService", void 0);
LogInModal = LogInModal_1 = __decorate([
    Component({
        name: 'LogInModal',
        components: {
            Alert,
            Screen,
            Socials
        }
    })
], LogInModal);
export { LogInModal };
export default LogInModal;
